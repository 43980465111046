import {RouteLeafRouting} from "@/my-leaf/routes/route-leaf-routing";
import {getDepth, getWeather, showInfo} from "@/my-leaf/routes/components/route-utils";
import {eventBus} from "@/main";
import dayjs from "dayjs";
import * as waypoints from "@/my-leaf/routes/components/route-waypoints";
import L from "leaflet";
import * as legs from "@/my-leaf/routes/components/route-legs";

let contextMenuButtonCount = 0

export class RouteLeaf extends RouteLeafRouting {
    waypoints = []
    selectedWaypointIndex = undefined;
    isBaypassMode = false
    selecteBaypassWaypoints = []
    isAutoroutingCancel = false
    depthsList = null

    async init(el) {
        super.initbaserouting(el);

        this.depthsList = new L.FeatureGroup();
        this.map.addLayer(this.depthsList);

        this.addNoGoLayer()

        eventBus.$off('draw-global-route')
        eventBus.$on('draw-global-route', () => {
            eventBus.$emit('control-clear-route')
            this.startRouteCalculation([], [], true, true)
        })

        this.map.on('contextmenu', (e) => {
            let id1 = 'map_depth' + contextMenuButtonCount;
            let id2 = 'map_info' + contextMenuButtonCount;
            let id3 = 'depths_info' + contextMenuButtonCount;
            let id4 = 'weather_info' + contextMenuButtonCount;

            let content = `<button id="` + id1 + `" onclick="" class="button-13">Depth</button>&nbsp;
                           <button id="` + id2 + `" onclick="" class="button-13">Map info</button>
                           <button id="` + id3 + `" onclick="" class="button-13">Depths info</button>
                           <button id="` + id4 + `" onclick="" class="button-13">Weather</button>`
            L.popup({autoClose: true}).setLatLng(e.latlng).setContent(content).openOn(this.map);
            let coordinates = e.latlng

            document.getElementById(id1).addEventListener('click', (e) => {
                this.map.closePopup()
                getDepth.call(this, coordinates)
            });

            document.getElementById(id2).addEventListener('click', (e) => {
                this.map.closePopup()
                showInfo(coordinates)
            });

            document.getElementById(id3).addEventListener('click', (e) => {
                this.map.closePopup()
                this.showDepths(coordinates)
            });

            document.getElementById(id4).addEventListener('click', (e) => {
                this.map.closePopup()

                let datetime = this.routesStore.getShipStartDate + ' ' + this.routesStore.getShipStartTime

                if (this.routesStore.getShipWaypointsCount === 0) {
                    const date = this.routesStore.getShipStartDate
                    const time = this.routesStore.getShipStartTime

                    const newDate = dayjs().add(this.routesStore.weatherSettings.dateOffset, 'days').format("YYYY-MM-DD");
                    const newTime = dayjs().add(this.routesStore.weatherSettings.timeOffset, 'hour').format("HH:mm");

                    datetime = dayjs(newDate + " " + newTime)
                }

                datetime = dayjs(datetime).unix()
                getWeather.call(this, coordinates, datetime)
            });

            contextMenuButtonCount++

            // }, 100); //TODO

        })

        this.restoreRoute()

    }

    makeRouteTable(updateRouteTable) {
        let route = []
        let info = []
        let safety = []

        // TODO
        // if (this.routesStore.routeResponse.routeLength > 0)
        //     return

        // console.log("aaaa ____ ", updateRouteTable)

        this.waypoints.forEach((waypoint, i) => {
            const lat =  waypoint.getLatLng().lat
            const lon =  waypoint.getLatLng().lng

            let leg = {
                lat: lat,
                lon: lon,
                radius: 120,
                xteLeft: 120,
                xteRight: 120,
                info: {
                    course: 0,
                    fromPreviousMeters: 0,
                    fromStartMeters: 0,
                    toFinishMeters: 0,
                    fromPreviousSeconds: 0,
                    fromStartSeconds: 0,
                    toFinishSeconds: 0
                },
                isDepthSafe: true,
                overallSafety: "safe"
            }

            let legInfo = {
                course: 0,
                fromPreviousMeters: 0,
                fromStartMeters: 0,
                toFinishMeters: 0,
                fromPreviousSeconds: 0,
                fromStartSeconds: 0,
                toFinishSeconds: 0
            }

            let legSafety = {
                lat: lat,
                lon: lon,
                safety: 1
            }

            route.push(leg)
            info.push(legInfo)
            safety.push(legSafety)
        })

        this.routesStore.addShipRouteResponse({routePoints: route, routePointInfos: info, routeLegs: safety, calculatedRouteLegs: route}, true, updateRouteTable)

        // this.routesStore.addRoutePoints(route)
        // this.routesStore.addRouteInfo(info)
    }

    cancelAutorouting() {
        this.clearRoute()
        this.isAutoroutingCancel = true
    }

    redrawRoute() {
        this.clearRoute(false, false, false)
        // this.drawGeneralRoute() //TODO
        this.routesStore.getShipWaypoints.forEach(waypoint => {
            waypoints.addWP.call(this, L.latLng(waypoint.lat, waypoint.lon))
        })
        legs.drawLegs.call(this, true, false)

    }

}
