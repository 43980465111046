<template>
  <div>
    <b-modal title="Images" v-model="images_modal" ok-only>
      <div class="col-9 col-sm-10 col-md-10 col-lg-10" style="overflow-y: scroll">
        <image-viewer :images=image_src></image-viewer>
      </div>
    </b-modal>

    <b-modal title="Audio" v-model="audio_modal" ok-only>
      <audio controls :src='audio_src'></audio>
    </b-modal>

    <b-modal title="Message" v-model="messages_modal" ok-only>
      {{messages}}
    </b-modal>

<!--    <h6 align="center" class="mt-2">LOGBOOK</h6>-->
    <div>

      <b-row>
        <b-col>
          <b-form-select v-model="$parent.$parent.selected_ship" :options="$parent.$parent.ships" size="sm"></b-form-select>
        </b-col>
      </b-row>
<!--      <b-row>-->
<!--        <b-col>-->
<!--          <b-form-select v-model="selected_route" :options="user_routes" size="sm"></b-form-select>-->
<!--        </b-col>-->
<!--      </b-row>-->

      <b-table selectable thead-class="d-none" select-mode="single" ref="logtable" @row-clicked="onRowClicked" :items="visibleRows()" :fields="visible_fields">
        <template #cell(images)="row">
          <b-button v-if="row.item.images.length > 0" class="unstyled-button" size="sm" title="Images" @click="showImages(row.item.images)">
            <b-icon variant="dark" icon="card-image" aria-hidden="true"></b-icon>
          </b-button>
        </template>
        <template #cell(audio)="row">
          <b-button v-if="row.item.audio.src" class="unstyled-button" size="sm" title="Images" @click="playAudio(row.item.audio)">
            <b-icon variant="dark" icon="mic" aria-hidden="true"></b-icon>
          </b-button>
        </template>
        <template #cell(message)="row">
          <b-button v-if="row.item.message" class="unstyled-button" size="sm" title="Images" @click="showMessages(row.item.message)">
            <b-icon variant="dark" icon="file-earmark-text" aria-hidden="true"></b-icon>
          </b-button>
        </template>
        <template #cell(time)="data">
          {{getDate(data.value)}}
        </template>
      </b-table>

<!--      <b-button size="sm" class="mr-2" variant="warning" @click="QRscan()">Upload more...</b-button>-->
<!--      <b-button size="sm" variant="warning" @click="Report()">Add report</b-button>-->


    </div>
  </div>
</template>

<script>
import {eventBus, logbooks_map, routes_map} from "@/main";
import router from "@/router";
import ImageViewer from "@/components/ImageViewer";
import {mapStores} from "pinia";
import {useRoutesStore} from "@/stores/routes";
import LogbookMap from "@/views/logbook/LogbookMap.vue";
import LogbookReport from "@/views/logbook/LogbookReport.vue";
import {useLogbookStore} from "@/stores/logbook";
import {useDeviceStore} from "@/stores/device";
import {useUserStore} from "@/stores/user";

export default {
  props: ["map"],
  components: {ImageViewer, LogbookReport: LogbookReport},
  data() {
    return {
      selected_ship: '',
      selected_route: '',
      images_modal: false,
      audio_modal: false,
      messages_modal: false,
      messages: "",
      image_src: "",
      audio_src: ""
    }
  },
  // components: {QRWindow},
  mounted() {
    // if (this.routesStore.routeResponse.routePoints.length > 0)
    //   this.map.drawRoute()

    eventBus.$off('select-logtable-row')
    eventBus.$on('select-logtable-row', (id) => {
      this.$refs.logtable.selectRow(id);
      const tbody = this.$refs.logtable.$el.querySelector('tbody')
      const row = tbody.querySelectorAll('tr')[id]
      row.scrollIntoView({block: 'center'})
    })
  },
  computed: {
    ...mapStores(useLogbookStore, useDeviceStore, useUserStore),
    user_ships() {
      this.selected_ship = this.deviceStore.getDeviceID
      return [
        { value: this.deviceStore.getDeviceID, text: this.deviceStore.getShipName}
      ]
    },
    user_routes() {
      this.selected_route = 'route_new'
      return [
        { value: 'route_new', text: 'New Route' }
      ]
    },
    images() {
      return this.$store.state.fickle.qr.images.map((image) => ({ //TODO: get images
        src: image.src,
        thumbnail: image.thumbnail ? image.thumbnail : image.src, // Используйте src в качестве миниатюры, если thumbnail не указан
        originalSize: [image.width, image.height].join('x'), // Ширина и высота оригинального изображения
        alt: image.alt || '', // Альтернативный текст (пустая строка, если не указан)
        height: image.height,
        width:  image.width,
        name: image.name
      }));
    },
    visible_fields() {
      return [
        {key: 'time', label: 'Time', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'message', label: '', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'images', label: '', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'audio', label: '', tdClass: 'small-black', thClass: 'small-gray'},
      ]
    },
  },
  methods: {
    visibleRows() {
      let items = this.logbookStore.logbook.filter((e) => e.deviceId === this.deviceStore.getDeviceID)
      return items
    },
    onRowSelected(items) {
      // logbooks_map.drawRoute()
      // return
      // if (items.length > 0) {
      //   // alert(items[0])
      //   // console.log(items[0])
      //   eventBus.$emit('show-log-on-map', items[0].time)
      // }
    },
    onRowClicked(row, index) {
      logbooks_map.panToReport(row.pos)
    },
    showImages(images) {

      // console.log(row)

      // this.image_src = this.$store.state.fickle.qr.shipLogs[0].logs[0].images[0].src
      // this.image_src = row.images[0].src
      this.image_src = images
      this.images_modal = true
    },
    playAudio(audio) {

      // console.log(row)

      this.audio_src = audio.src
      this.audio_modal = true

    },
    showMessages(message) {
      this.messages = message
      this.messages_modal = true
    },
    getDate(date) {

      let t = new Date(date)

      let hr = ("0" + t.getHours()).slice(-2);
      let min = ("0" + t.getMinutes()).slice(-2);
      let sec = ("0" + t.getSeconds()).slice(-2);

      return t.getFullYear()+"-"+parseInt(t.getMonth()+1)+"-"+t.getDate()+" "+hr+":"+min+":"+sec
    },
    QRscan() {
        router.push({name:'ships'})
        setTimeout(() => {
          eventBus.$emit('start-scan-qr-ecdis')
        }, 500)
    },
    Report() {
      // alert(1)
      this.$root.$emit('bv::toggle::collapse', 'logbook-sidebar');
      return
      
        // router.push({name:'ships'})
        // setTimeout(() => {
        //   eventBus.$emit('start-scan-qr-ecdis')
        // }, 500)
    },
  }
}
</script>

<style scoped>
.unstyled-button {
  border: none;
  padding: 0;
  background: none;
}
</style>