import {defineStore} from 'pinia'
import {useDeviceStore} from "@/stores/device";
import {eventBus} from "@/main";
import Vue from "vue";
import dayjs from "dayjs";

export const useRoutesStore = defineStore({
    id: 'routes',
    state: () => ({
        routes: [],

        weatherSettings: {
            dateOffset: 0,
            timeOffset: 0,
        },
        routeResponse: {
            routeLength: 0,
            routePoints: [],
            routePointInfos: [],
            dangerObjects: [],
            debugInfo: [],
            restoreRoutePoints: [],
            routeLegs: [],
            calculatedRouteLegs: []
        },
        routeResponseBackup: {
            startIndex: 0,
            finishIndex: 0,
            isBypass: false,
            routeLength: 0,
            routePoints: [],
            routePointInfos: [],
            dangerObjects: [],
            debugInfo: [],
            restoreRoutePoints: [],
            routeLegs: [],
            calculatedRouteLegs: []
        },
        routeSettings: {
            draught: "5",
            height: "20",
            radius: "120",
            xtel: "120",
            xter: "120",
            speed: "10",
            trackratio: "0.7",
            shallowratio: "1",
            avoidshallowstrength: "1",
            avoiddistance: "120",
            startdate: '',
            starttime: '',
            userecommendedtracks: true,
            usegeneralroutes: true,
            usesuez: true,
            usepanama: true,
            usebering: true,
            userivers: false
        },
        routeWeather: {
            weatherInWaypoints: []
        },
        nogoZones: []

    }),
    // setters: {
    //     setA: (state) => state.routeSettings.draught,
    // },
    getters: {
        getShipIndex: (state) => {
            const deviceStore = useDeviceStore()
            const deviceID = deviceStore.getDeviceID
            return state.routes?.findIndex((a) => a?.deviceId === deviceID)
        },
        getShipSettings: (state) => {
            const deviceStore = useDeviceStore()
            const deviceID = deviceStore.getDeviceID
            return state.routes?.find((a) => a?.deviceId === deviceID)?.routeSettings
        },
        getShipResponse: (state) => {
            const deviceStore = useDeviceStore()
            const deviceID = deviceStore.getDeviceID
            return state.routes?.find((a) => a?.deviceId === deviceID)?.routeResponse
        },
        getShipResponseBackup: (state) => {
            const deviceStore = useDeviceStore()
            const deviceID = deviceStore.getDeviceID
            return state.routes?.find((a) => a?.deviceId === deviceID)?.routeResponseBackup
        },
        getShipDraught() {
            return Number(this.getShipSettings?.draught ?? 5)
        },
        getShipHeight() {
            return Number(this.getShipSettings?.height)
        },
        getShipSpeed() {
            return Number(this.getShipSettings?.speed)
        },
        getShipRadius() {
            return Number(this.getShipSettings?.radius)
        },
        getShipStartDate() {
            return this.getShipSettings?.startdate
        },
        getShipStartTime() {
            return this.getShipSettings?.starttime
        },
        getShipXtel() {
            return Number(this.getShipSettings?.xtel)
        },
        getShipXter() {
            return Number(this.getShipSettings?.xter)
        },
        getShipXTE() {
            return Math.max(Number(this.getShipSettings?.xtel), Number(this.getShipSettings?.xter)) * 2
        },
        getShipTrackratio() {
            return Number(this.getShipSettings?.trackratio)
        },
        getShipShallowratio() {
            return Number(this.getShipSettings?.shallowratio)
        },
        getShipAvoidshallowstrength() {
            return Number(this.getShipSettings?.avoidshallowstrength)
        },
        getShipAvoiddistance() {
            return Number(this.getShipSettings?.avoiddistance)
        },
        getShipUserecommendedtracks() {
            return this.getShipSettings?.userecommendedtracks
        },
        getShipUsegeneralroutes() {
            return this.getShipSettings?.usegeneralroutes
        },
        getShipUsesuez() {
            return this.getShipSettings?.usesuez
        },
        getShipUsepanama() {
            return this.getShipSettings?.usepanama
        },
        getShipUsebering() {
            return this.getShipSettings?.usebering
        },
        getShipUserivers() {
            return this.getShipSettings?.userivers
        },


        getShipWaypoints() {
            return this.getShipResponse?.routePoints
        },
        getShipWaypointsCount() {
            return this.getShipResponse?.routePoints.length
        },
        getShipLeg: (state) => {
            return (index) => {
                const deviceStore = useDeviceStore()
                const deviceID = deviceStore.getDeviceID
                const resp = state.routes?.find((a) => a?.deviceId === deviceID)?.routeResponse
                return resp?.routePoints[index]

            }
        },
        getShipLegSafety: (state) => {
            return (index) => {
                const deviceStore = useDeviceStore()
                const deviceID = deviceStore.getDeviceID
                const resp = state.routes?.find((a) => a?.deviceId === deviceID)?.routeResponse
                return resp?.routePoints[index]?.isDepthSafe ?? true

            }
        },
        getShipLegInfo: (state) => {
            return (index) => {
                const deviceStore = useDeviceStore()
                const deviceID = deviceStore.getDeviceID
                const resp = state.routes?.find((a) => a?.deviceId === deviceID)?.routeResponse
                return resp?.routePoints[index]?.info

            }
        },
        getShipLegWarnings: (state) => {
            return (index) => {
                const deviceStore = useDeviceStore()
                const deviceID = deviceStore.getDeviceID
                const resp = state.routes?.find((a) => a?.deviceId === deviceID)?.routeResponse
                return resp?.routePoints[index]?.overallSafety ?? 'safe'

            }
        },
        getShipLegHazards: (state) => {
            return (index) => {
                const deviceStore = useDeviceStore()
                const deviceID = deviceStore.getDeviceID
                const resp = state.routes?.find((a) => a?.deviceId === deviceID)?.routeResponse
                return resp?.routePoints[index]?.dangerObjects ?? []

            }
        },

        // getShipLegSafety(index) {
        //     return this.getShipResponse?.routePoints[index]?.isDepthSafe ?? true
        // },
        // getShipLeg(index) {
        //     return this.getShipResponse?.routePoints[index]
        // },
        // getShipLegInfo(index) {
        //     return this.getShipResponse?.routePoints[index].info
        // },

        // getShipLegWarnings(index) {
        //     return this.getShipResponse?.routePoints[index]?.overallSafety ?? 'safe'
        // },
        // getShipLegHazards(index) {
        //     return this.getShipResponse?.routePoints[index]?.dangerObjects ?? []
        // },

        // getXtel: (state) => Number(state.routeSettings.xtel),
        // getXter: (state) => Number(state.routeSettings.xter),
        // getXTE: (state) => Math.max(Number(state.routeSettings.xtel), Number(state.routeSettings.xter)) * 2,
        // getDraught: (state) => Number(state.routeSettings.draught),
        // getStartDate: (state) => state.routeSettings.startdate,
        // getStartTime: (state) => state.routeSettings.starttime,
        // getHeight: (state) => Number(state.routeSettings.height),
        // getSpeed: (state) => Number(state.routeSettings.speed),
        // getRadius: (state) => Number(state.routeSettings.radius),
        // getTrackratio: (state) => Number(state.routeSettings.trackratio),
        // getShallowratio: (state) => Number(state.routeSettings.shallowratio),
        // getAvoidshallowstrength: (state) => Number(state.routeSettings.avoidshallowstrength),
        // getAvoiddistance: (state) => Number(state.routeSettings.avoiddistance),

        // getShipWaypoints: (state) => state.routeResponse.routePoints,
        // getShipWaypointsCount: (state) => state.routeResponse.routePoints.length,
        // getLeg: (state) => {
        //     return (index) => state.routeResponse.routePoints[index]
        // },
        // getShipLegInfo: (state) => {
        //     return (index) => state.routeResponse.routePoints[index].info
        // },
        // getShipLegSafety: (state) => {
        //     return (index) => state.routeResponse.routePoints[index]?.isDepthSafe ?? true
        // },
        // getShipLegWarnings: (state) => {
        //     return (index) => state.routeResponse.routePoints[index]?.overallSafety ?? 'safe'
        // },
        // getShipLegHazards: (state) => {
        //     return (index) => state.routeResponse.routePoints[index]?.dangerObjects ?? []
        // },


        // getDraughtByShip() {
        //     return this.getSettingsByShip?.routeSettings?.draught
        //     // return Number(this.getSettingsByShip.routeSettings.draught)
        // },
        // getSettingsByShip1: (state) => {
        //     const deviceStore = useDeviceStore()
        //     const deviceID = deviceStore.getDeviceID
        //
        //     let route = state.routes.find((a) => a.deviceId == deviceID)
        //     return route
        //
        //     // let flag = false
        //     // let theAgreement = null
        //     // state.agreements.forEach(agreement => {
        //     //     let deviceID = agreement?.users.find((a) => a.deviceId == val)
        //     //     if (deviceID) {
        //     //         flag = true
        //     //         theAgreement = agreement
        //     //         return
        //     //     }
        //     // })
        //     // return theAgreement
        // },

    },
    actions: {
        // addRouteDate(val) {
        //     console.log("dddd1_", this.routes.length)
        //     this.routes.forEach(route => {
        //         console.log("dddd2_", route.routeSettings, val)
        //         route.routeSettings.startdate = val
        //     })
        //     // this.routes[this.getShipIndex].routeSettings.startdate = val
        // },
        // addRouteTime(val) {
        //     this.routes[this.getShipIndex].routeSettings.starttime = val
        // },

        addShips(installations) {
            let now = dayjs()
            let date = dayjs(now).format("YYYY-MM-DD")
            let time = dayjs(now).format("HH:mm:ss")

            installations.forEach(installation => {
                let shipRoute = {
                    deviceId: installation.deviceId,
                    shipName: installation.shipName,
                    routeSettings: {
                        draught: "5",
                        height: "20",
                        radius: "120",
                        xtel: "120",
                        xter: "120",
                        speed: "10",
                        trackratio: "0.7",
                        shallowratio: "1",
                        avoidshallowstrength: "1",
                        avoiddistance: "120",
                        startdate: date,
                        starttime: time,
                        userecommendedtracks: true,
                        usegeneralroutes: true,
                        usesuez: true,
                        usepanama: true,
                        usebering: true,
                        userivers: false
                    },
                    routeResponse: {
                        routeLength: 0,
                        routePoints: [],
                        routePointInfos: [],
                        dangerObjects: [],
                        debugInfo: [],
                        restoreRoutePoints: [],
                        routeLegs: [],
                        calculatedRouteLegs: []
                    },
                    routeResponseBackup: {
                        startIndex: 0,
                        finishIndex: 0,
                        isBypass: false,
                        routeLength: 0,
                        routePoints: [],
                        routePointInfos: [],
                        dangerObjects: [],
                        debugInfo: [],
                        restoreRoutePoints: [],
                        routeLegs: [],
                        calculatedRouteLegs: []
                    },
                }
                this.routes.push(shipRoute)
            })
        },
        addShipRouteResponse(val, isNeedCheckSafety, updateRouteTable) {
            function findUpdatedElement(arr1, arr2) {
                return arr1.findIndex((element, index) =>
                    element?.lat !== arr2[index]?.lat || element.lon !== arr2[index].lon);
            }

            if (updateRouteTable) {
                if (this.getShipResponse.routePoints.length !== val.calculatedRouteLegs.length) {
                    eventBus.$emit('wp-control-toggle-check-safety', true)
                } else {
                    this.getShipResponse.routePoints.forEach((item, index) => {
                        if (item.lat !== val.calculatedRouteLegs[index].lat || item.lon !== val.calculatedRouteLegs[index].lon) {
                            eventBus.$emit('wp-control-toggle-check-safety', true)
                        }
                    })
                }
            }

            if (this.getShipResponse.routePoints.routeLength === 0) {
                this.getShipResponse.routeLength = val.routeLength ?? 0
                this.getShipResponse.routePoints = val.calculatedRouteLegs
                this.getShipResponse.calculatedRouteLegs = val.calculatedRouteLegs
                this.getShipResponse.routePointInfos = val.routePointInfos
                this.getShipResponse.dangerObjects = val.dangerObjects ?? []
                this.getShipResponse.debugInfo = val.debugInfo ?? []
                this.getShipResponse.restoreRoutePoints = val.restoreRoutePoints ?? []
                this.getShipResponse.routeLegs = val.routeLegs ?? []
            }

            if (this.getShipResponse.routePoints.routeLength > 0 && updateRouteTable) {
                if (this.getShipResponse.routePoints.length === val.calculatedRouteLegs.length) {
                    let index = findUpdatedElement(this.getShipResponse.routePoints, val.routePoints)
                    if (index !== -1) {
                        this.getShipResponse.routePoints[index].lat = val.calculatedRouteLegs[index].lat
                        this.getShipResponse.routePoints[index].lon = val.calculatedRouteLegs[index].lon
                        this.getShipResponse.routeLegs[index].lat = val.calculatedRouteLegs[index].lat
                        this.getShipResponse.routeLegs[index].lon = val.calculatedRouteLegs[index].lon
                    }
                }

                if (this.getShipResponse.routePoints.length > val.calculatedRouteLegs.length) {
                    let index = findUpdatedElement(this.getShipResponse.routePoints, val.calculatedRouteLegs)
                    if (index !== -1) {
                        this.getShipResponse.routePoints.splice(index, 1)
                        this.getShipResponse.routePointInfos.routeLegs.splice(index, 1)
                        this.getShipResponse.routeLegs.splice(index, 1)
                    }
                }

                if (this.getShipResponse.routePoints.length < val.calculatedRouteLegs.length) {
                    let index = findUpdatedElement(val.calculatedRouteLegs, this.getShipResponse.routePoints)
                }

            } else {
                this.getShipResponse.routeLength = val.routeLength ?? 0
                this.getShipResponse.routePoints = val.calculatedRouteLegs
                this.getShipResponse.calculatedRouteLegs = val.calculatedRouteLegs
                this.getShipResponse.routePointInfos = val.routePointInfos
                this.getShipResponse.dangerObjects = val.dangerObjects ?? []
                this.getShipResponse.debugInfo = val.debugInfo ?? []
                this.getShipResponse.restoreRoutePoints = val.restoreRoutePoints ?? []
                this.getShipResponse.routeLegs = val.routeLegs ?? []
            }

            let i = 0
            this.getShipResponse.routePoints.forEach(item => {
                item.id = i++
            })
        },
        resetShip() {
            this.getShipResponse.routeLength = 0
            this.getShipResponse.routePoints = []
            this.getShipResponse.routePointInfos = []
            this.getShipResponse.dangerObjects = []
            this.getShipResponse.debugInfo = []
            this.getShipResponse.restoreRoutePoints = []
            this.getShipResponse.routeLegs = []
            this.getShipResponse.calculatedRouteLegs = []
        },
        backupShipResponse(startIndex, finishIndex) {
            this.getShipResponseBackup.startIndex = startIndex
            this.getShipResponseBackup.finishIndex = finishIndex
            this.getShipResponseBackup.isBypass = true
            this.getShipResponseBackup.routeLength = this.routeResponse.routeLength
            this.getShipResponseBackup.routePoints = this.routeResponse.routePoints
            this.getShipResponseBackup.calculatedRouteLegs = this.routeResponse.calculatedRouteLegs
            this.getShipResponseBackup.routePointInfos = this.routeResponse.routePointInfos
            this.getShipResponseBackup.dangerObjects = this.routeResponse.dangerObjects
            this.getShipResponseBackup.debugInfo = this.routeResponse.debugInfo
            this.getShipResponseBackup.restoreRoutePoints = this.routeResponse.restoreRoutePoints
            this.getShipResponseBackup.routeLegs = this.routeResponse.routeLegs
        },
        resetShipBypassBackup() {
            if (this.getShipResponseBackup) {
                this.getShipResponseBackup.startIndex = 0
                this.getShipResponseBackup.finishIndex = 0
                this.getShipResponseBackup.isBypass = false
                this.getShipResponseBackup.routeLength = 0
                this.getShipResponseBackup.routePoints = []
                this.getShipResponseBackup.routePointInfos = []
                this.getShipResponseBackup.dangerObjects = []
                this.getShipResponseBackup.debugInfo = []
                this.getShipResponseBackup.restoreRoutePoints = []
                this.getShipResponseBackup.routeLegs = []
                this.getShipResponseBackup.calculatedRouteLegs = []
            }
        },

        restoreShipRouteSettings(val) {
            const deviceStore = useDeviceStore()
            const deviceID = deviceStore.getDeviceID
            this.routes.find((a) => a?.deviceId === deviceID).routeSettings = val


            // this.getShipSettings = val
        },
        restoreShipRouteResponse(val) {
            const deviceStore = useDeviceStore()
            const deviceID = deviceStore.getDeviceID
            this.routes.find((a) => a?.deviceId === deviceID).routeResponse = val



            // this.getShipResponse = val
        },



        //==========================================================
        //==========================================================
        //==========================================================
        //==========================================================

        initWeather(count) {
            let points = []
            this.routeWeather.weatherInWaypoints.forEach(item => {
                points.push({temperature: 'N/A', wind: 'N/A', windAngle: 'N/A', datetime: 0})
            })
            this.routeWeather.weatherInWaypoints = points;
        },
        addWeather(val, index) {
            Vue.set(this.routeWeather.weatherInWaypoints, index, val)
        },


        //==========================================================
        //==========================================================
        //==========================================================
        //==========================================================



        addRouteResponse(val, isNeedCheckSafety, updateRouteTable) {
            function findUpdatedElement(arr1, arr2) {
                return arr1.findIndex((element, index) =>
                    element?.lat !== arr2[index]?.lat || element.lon !== arr2[index].lon);
            }

            if (updateRouteTable) {
                if (this.routeResponse.routePoints.length !== val.calculatedRouteLegs.length) {
                    eventBus.$emit('wp-control-toggle-check-safety', true)
                } else {
                    this.routeResponse.routePoints.forEach((item, index) => {
                        if (item.lat !== val.calculatedRouteLegs[index].lat || item.lon !== val.calculatedRouteLegs[index].lon) {
                            eventBus.$emit('wp-control-toggle-check-safety', true)
                        }
                    })
                }
            }

            if (this.routeResponse.routePoints.routeLength === 0) {
                this.routeResponse.routeLength = val.routeLength ?? 0
                this.routeResponse.routePoints = val.calculatedRouteLegs
                this.routeResponse.calculatedRouteLegs = val.calculatedRouteLegs
                this.routeResponse.routePointInfos = val.routePointInfos
                this.routeResponse.dangerObjects = val.dangerObjects ?? []
                this.routeResponse.debugInfo = val.debugInfo ?? []
                this.routeResponse.restoreRoutePoints = val.restoreRoutePoints ?? []
                this.routeResponse.routeLegs = val.routeLegs ?? []
            }

            if (this.routeResponse.routePoints.routeLength > 0 && updateRouteTable) {
                if (this.routeResponse.routePoints.length === val.calculatedRouteLegs.length) {
                    let index = findUpdatedElement(this.routeResponse.routePoints, val.routePoints)
                    if (index !== -1) {
                        this.routeResponse.routePoints[index].lat = val.calculatedRouteLegs[index].lat
                        this.routeResponse.routePoints[index].lon = val.calculatedRouteLegs[index].lon
                        this.routeResponse.routeLegs[index].lat = val.calculatedRouteLegs[index].lat
                        this.routeResponse.routeLegs[index].lon = val.calculatedRouteLegs[index].lon
                    }
                }

                if (this.routeResponse.routePoints.length > val.calculatedRouteLegs.length) {
                    let index = findUpdatedElement(this.routeResponse.routePoints, val.calculatedRouteLegs)
                    if (index !== -1) {
                        this.routeResponse.routePoints.splice(index, 1)
                        this.routePointInfos.routeLegs.splice(index, 1)
                        this.routeResponse.routeLegs.splice(index, 1)
                    }
                }

                if (this.routeResponse.routePoints.length < val.calculatedRouteLegs.length) {
                    let index = findUpdatedElement(val.calculatedRouteLegs, this.routeResponse.routePoints)
                }

            } else {
                this.routeResponse.routeLength = val.routeLength ?? 0
                this.routeResponse.routePoints = val.calculatedRouteLegs
                this.routeResponse.calculatedRouteLegs = val.calculatedRouteLegs
                this.routeResponse.routePointInfos = val.routePointInfos
                this.routeResponse.dangerObjects = val.dangerObjects ?? []
                this.routeResponse.debugInfo = val.debugInfo ?? []
                this.routeResponse.restoreRoutePoints = val.restoreRoutePoints ?? []
                this.routeResponse.routeLegs = val.routeLegs ?? []
            }

            let i = 0
            this.routeResponse.routePoints.forEach(item => {
                item.id = i++
            })

        },
        backupResponse(startIndex, finishIndex) {
            this.routeResponseBackup.startIndex = startIndex
            this.routeResponseBackup.finishIndex = finishIndex
            this.routeResponseBackup.isBypass = true
            this.routeResponseBackup.routeLength = this.routeResponse.routeLength
            this.routeResponseBackup.routePoints = this.routeResponse.routePoints
            this.routeResponseBackup.calculatedRouteLegs = this.routeResponse.calculatedRouteLegs
            this.routeResponseBackup.routePointInfos = this.routeResponse.routePointInfos
            this.routeResponseBackup.dangerObjects = this.routeResponse.dangerObjects
            this.routeResponseBackup.debugInfo = this.routeResponse.debugInfo
            this.routeResponseBackup.restoreRoutePoints = this.routeResponse.restoreRoutePoints
            this.routeResponseBackup.routeLegs = this.routeResponse.routeLegs
        },
        addRouteDangerObjects(val) {
            this.routeDangerObjects = val
        },
        addRouteGeneral(val) {
            this.routeGeneral = val
        },
        addRouteLegs(val) {
            this.routeLegs = val
        },
        restoreRouteSettings(val) {
            this.routeSettings = val
        },
        restoreRouteResponse(val) {
            this.routeResponse = val
        },
        addNoGoZone(val) {
            this.nogoZones = val
        },
        deleteNoGoZone(val) {
            let index = this.nogoZones.findIndex(p => p.name == val);
            this.nogoZones.splice(index, 1);
        },
        addWeatherSettings(dateOffset, timeOffset) {
            this.weatherSettings.dateOffset = dateOffset
            this.weatherSettings.timeOffset = timeOffset
        },



        // this.routeResponseBackup = {
        //     routeLength: 0,
        //     routePoints: [],
        //     routePointInfos: [],
        //     dangerObjects: [],
        //     debugInfo: [],
        //     restoreRoutePoints: [],
        //     routeLegs: []
        // }
    },
    persist:
        false
})
