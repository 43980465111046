<template>
  <b-overlay :show="preparingCells" rounded="sm" spinner-variant="danger">

    <b-modal title="Import list of cells" v-model="openCellsList" ok-only @ok="primarMapBoosterByList">
      <b-form-textarea v-model="cellList"
                       placeholder="Paste comma or new line separated cells names..."></b-form-textarea>
    </b-modal>

    <div>

      <b-container v-if="!orderPlaced" class="mt-2">
        <b-row>
          <b-col cols="6">
            <b-card class="px-2" no-body bg-variant="light">
              <b-form inline>
                <b-form-select v-if="deviceStore.getAgreementProvider === 'UKHO'" v-model="selectedType_UKHO" size="sm"
                               :options="[
                { value: 'AVCS', text: 'AVCS' },
                { value: 'ADP', text: 'ADP' , disabled: true },
                { value: 'eNP', text: 'eNP', disabled: true }]"></b-form-select>
                <b-form-select v-if="deviceStore.getAgreementProvider === 'Primar'" v-model="selectedType_Primar"
                               size="sm" :options="[
                { value: 'ENC', text: 'ENC', disabled: true }]"></b-form-select>
                <b-form-select v-model="selectedPeriod" size="sm" class="ml-1" :options="[
                { value: '4', text: '3 months' },
                { value: '1', text: '6 months' },
                { value: '0', text: '12 months' }]"></b-form-select>
              </b-form>
            </b-card>
          </b-col>

          <b-col>
            <b-button v-if="currentCartLen === 0"
                      size="sm" variant="info" @click="importClipboard()">Import
            </b-button>

            <b-button
                class="ml-1"
                :disabled="currentCartLen === 0 || getTotalPrice() === '' || getTotalPrice() === '0.00'"
                size="sm" variant="success" @click="placeOrder()">Submit
            </b-button>

          </b-col>
        </b-row>
        <b-row style="height: 30px">
          <b-col>
            <span class="smt2s" v-if="getTotalPrice()">
              Total price: {{ getDisplayTotalPrice() }}<br>
            </span>
            <span class="smt2s" v-else-if="priceCalculating">
                Calculating price...
            </span>
          </b-col>

          <b-button v-b-tooltip.hover title="Select all" v-if="filterSelectAll && currentCartLen > 0"
                    class="btn shadow-none border-0 float-right" size="sm" variant="outline-secondary"
                    @click="toggleBands('on')">
            <b-icon icon="square" font-scale="1.2"></b-icon>
          </b-button>
          <b-button v-b-tooltip.hover title="Unselect all" v-if="!filterSelectAll && currentCartLen > 0"
                    class="btn shadow-none border-0 float-right" size="sm" variant="outline-secondary"
                    @click="toggleBands('off')">
            <b-icon icon="check-square" font-scale="1.2"></b-icon>
          </b-button>

        </b-row>
      </b-container>

      <b-table v-if="!orderPlaced" ref="orderstable" selectable select-mode="single" sticky-header="420px"
               @row-selected="onRowSelected"
               :items="visibleRows()"
               :fields="visible_fields">

        <template #cell(id)="row">
<!--          <div v-if="deviceStore.getAgreementProvider === 'UKHO'">-->
          <span v-if="purchasedEarly(row.item.id)">
            <span class="small-green">{{ row.item.id }}</span><br>
            <span class="smt2s">Valid till: {{ getCellValidDate(row.item.id) }}</span>
          </span>
            <span v-else>
            {{ row.item.id }}
          </span>
<!--          </div>-->
<!--          <div v-else>-->
<!--            {{ row.item.id }}-->
<!--          </div>-->
        </template>

        <template #cell(band)="row">
          <span class="small-gray-card">{{ bandName(cellById(row.item.id).usageBand) }}</span>
        </template>

        <template #cell(bandid)="row">
          <span class="small-gray-card">{{ cellById(row.item.id).usageBand }}</span>
        </template>

        <template #cell(price)="row">
          {{ getCellDisplayPrice(row.item.id) }}
        </template>

        <template #cell(selected)="row">
          <b-checkbox switch :checked="!row.item.disabled" @change=toggleCellInclusion(row.item.id)></b-checkbox>
        </template>
      </b-table>

      <b-container v-if="orderPlaced" class="mt-2">
        <b-row>
          <b-col cols="8">
            <b-card no-body bg-variant="light" class="px-2 py-2">
            <span class="smt2s">
              Agreement: {{ deviceStore.getAgreementName }}<br>
              Ship: {{userStore.getShipName}}
              <hr>
              <div v-if="deviceStore.getAgreementProvider === 'UKHO'">
                AVCS: {{ currentCartEnabledItemsLen }} items<br>
                AVCS period: {{ this.getDisplayPeriod() }} months<br>
                AVCS price: {{ getDisplayTotalPrice() }}<br><br>

                ADP: 0 items<br>
                ADP period: {{ this.getDisplayPeriod() }} months<br>
                ADP price: 0€<br><br>

                eNP: 0 items<br>
                eNP period: {{ this.getDisplayPeriod() }} months<br>
                eNP price: 0€<br><br>
              </div>

              <div v-if="deviceStore.getAgreementProvider === 'Primar'">
                ENC: {{ currentCartEnabledItemsLen }} items<br>
                ENC period: {{ this.getDisplayPeriod() }} months<br>
                ENC price: {{getDisplayTotalPrice()}} <br><br>
              </div>
                <b>Total price: {{getDisplayTotalPrice()}}</b><br>
            </span>
            </b-card>
          </b-col>
          <b-col align-self="center">
            <b-button block size="sm" variant="success" @click="approveOrder()">Approve</b-button>
            <b-button block size="sm" variant="danger" @click="cancelOrder()">Cancel</b-button>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </b-overlay>
</template>

<script>
import {eventBus} from "@/main";
import * as utils from "@/utils/utils";
import {debounce} from "lodash"
import {myAxios, myAxiosRetry} from "@/services/myAxios";
import router from "@/router";
import {mapStores} from "pinia";
import {useRoutesStore} from "@/stores/routes";
import {useMomentaryStore} from "@/stores/momentary";
import {useDeviceStore} from "@/stores/device";
import {useUserStore} from "@/stores/user";
import {loadMSSChartsByRoute} from "@/helpers/api";
import logbook from "@/views/logbook/index.vue";

export default {
  name: "OrdersTable",
  // props: ['map'],
  data() {
    return {
      // sortBy: 'bandid',
      filterSelectAll: false,
      preparingCells: false,
      orderPlaced: false,
      viewParent: this.$parent.$parent.$parent.$parent.$parent,
      selectedType_UKHO: 'AVCS',
      selectedType_Primar: 'ENC',
      selectedPeriod: '4',
      priceCalculating: false,
      openCellsList: false,
      cellList: ""
    }
  },
  mounted() {
    // this.prepareCells()
    eventBus.$off('make-row-active')
    eventBus.$on('make-row-active', (id) => {
      this.onCellClicked(id)
    })

    // this.getHistory().then(resp => {
    //   this.deviceStore.addOrdersHistoryAVCS(resp.avcsCells)
    // })
  },
  watch: {
    currentCartLen() {
      // this.$parent.checkPeriod()
      this.debounceCountTotalPrice()
      this.debounceCheckOrdersHistory()
      // this.countTotalPrice()
    },
    selectedPeriod() {
      this.debounceCountTotalPrice()
    }
  },
  computed: {
    ...mapStores(useRoutesStore, useMomentaryStore, useUserStore, useDeviceStore),
    currentCartLen() {
      return this.$store.state.current.cart.s63cells.length
    },
    currentCartEnabledItemsLen() {
      const enabledItems = this.$store.state.current.cart.s63cells.filter(function (e) {
        return !e.disabled;
      })
      return enabledItems.length
    },
    debounceCountTotalPrice() {
      return debounce(this.countTotalPrice, 500);
    },
    debounceCheckOrdersHistory() {
      return debounce(this.checkOrdersHistory, 500);
    },
    visible_fields() {
      return [
        {key: 'selected', label: 'Selected', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'id', label: 'ID', tdClass: 'small-black', thClass: 'small-gray'},
        // {key: 'bandid', label: 'Band ID', tdClass: 'small-black', thClass: 'small-gray', sortable: true},
        {key: 'band', label: 'Band', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'price', label: 'Price', tdClass: 'small-black', thClass: 'small-gray'},
      ]
    },

  },
  methods: {
    importClipboard() {
      this.openCellsList = true;
    },
    primarMapBoosterByList() {
      this.openCellsList = false;
      let cells = this.cellList.split(/[\n,]/);

      cells.forEach((item) => {
        eventBus.$emit('a1', item.trim())
        // this.map.onCellClicked(item.trim());
      });
      eventBus.$emit('a4');
      // this.map.dispatchSelectedCells();
    },
    getHistory() {
      return new Promise(resolve => {
            if (!this.deviceStore.getDeviceID) {
              resolve()
              return
            }

            if (this.deviceStore.getAgreementProvider === 'UKHO') {
              let URL = "/api/v1/mss/summary/deviceid/" + this.deviceStore.getDeviceID;
              myAxios.get(URL).then(resp => {
                resolve(resp.data);
              }).catch((error) => {
                console.log(error)
              }).finally(() => {
              })
            }

            if (this.deviceStore.getAgreementProvider === 'Primar') {
              let URL = "/api/v1/orders/deviceid/" + this.deviceStore.getDeviceID;
              myAxios.get(URL).then(resp => {
                console.log("aaaa", resp);
                resolve(resp.data);
              }).catch((error) => {
                console.log(error)
              }).finally(() => {
              })
            }
          }
      )
          ;
    },
    purchasedEarly(id) {
      const purchased = this.deviceStore.orderedData.ukho.avcs.filter(function (e) {
        return e.name === id;
      })

      return purchased.length > 0
    }
    ,
    getCellValidDate(id) {
      const purchased = this.deviceStore.orderedData.ukho.avcs.filter(function (e) {
        return e.name === id;
      })
      return purchased[0]['expiryDate']
    }
    ,

    getCurrency() {
      return this.deviceStore.getAgreementCurrency
    }
    ,

    getTotalPrice() {
      let currency = this.deviceStore.getAgreementCurrency
      if (currency === 'USD')
        return this.$store.state.current.cart.totalPriceByPrimarAgreementUSD
      else if (currency === 'RUB')
        return this.$store.state.current.cart.totalPriceByPrimarAgreementRUB
      else return ''
    }
    ,


    getType(type) {
      if (type === 'AVCS')
        return 'info'
      else
        return 'outline-info'
    }
    ,
    checkOrdersHistory() {
      let isNeedRecalculate = false
      this.currentCart().forEach(item => {
        if (!item.disabled && this.purchasedEarly(item.id)) {
          this.toggleCellInclusion(item.id, 'off')
          isNeedRecalculate = true
        }
      })

      if (isNeedRecalculate) {
        this.debounceCountTotalPrice()
        // setTimeout(function () {
        //   this.countTotalPrice()
        // }, 1000);
      }

    }
    ,
    countTotalPrice() {
      // alert(1)

      if (this.currentCartLen === 0)
        return

      this.priceCalculating = true;

      // hide price while change period
      this.$store.commit("current/setTotalPriceByPrimar",
          {usd: '?', rub: '?', rubineuro: '?'}
      );

      // let agreementID = this.hasValidAgreement ? this.$store.state.proto.agreement.id : "-1"
      // let deviceID = this.$store.state.proto.order.device_id
      // let appType = this.$store.state.proto.order.application_id
      let deviceID = this.deviceStore.getDeviceID
      let agreement = this.deviceStore.getAgreementID
      let appType = this.deviceStore.getAgreementApplicationType
      let period = this.selectedPeriod

      console.log("aaaa", deviceID, agreement, appType)


      // if (this.selectedPeriod === '6') {
      //   period = '1'
      // } else if (this.selectedPeriod === '12') {
      //   period = '0'
      // }

      this.$store.dispatch("current/getTotalPrice", {
        products: this.currentCart(),
        periodBand: period,
        agreementID: agreement,
        deviceID: deviceID,
        appType: appType
      }).then(() => {
        this.priceCalculating = false;
      })
    }
    ,
    currentCart() {
      return this.$store.state.current.cart.s63cells
    }
    ,

    toggleBands(type) {
      if (type === 'on' || type === 'off') {
        this.filterSelectAll = !this.filterSelectAll
        this.currentCart().forEach(item => {
          this.toggleCellInclusion(item.id, type)
        })
        // this.debounceCountTotalPrice()
        // this.countTotalPrice()
      } else {
        this.currentCart().forEach(item => {
          console.log("aaaa", item)
          // alert(this.bandName(item.usageBand) + "," + type + ", " + item.usageBand)

          if (this.bandName(this.cellById(item.id).usageBand) === type) {
            this.toggleCellInclusion(item.id)
          }
        })

        // this.countTotalPrice()

      }
      this.debounceCountTotalPrice()
      // if (type === 'general-on') {
      //   this.currentCart().forEach(item => {
      //     if (item.bandName(item.usageBand) === "general")
      //       this.toggleCellInclusion(item.id)
      //   })
      //   this.countTotalPrice()
      // }

    }
    ,

    placeOrder() {
      this.orderPlaced = true
    }
    ,
    clearOrder() {
      console.log("cccc: clearOrder")
      this.$store.dispatch('current/clearCart')
      this.$store.dispatch('addS63Items', [])
              // window.location.reload();
              // this.map.clearCellslayer()
      this.map.clearSelectedCells()
    }
    ,
    cancelOrder() {
      this.orderPlaced = false
    }
    ,

    onCellClicked(id) {
      // alert(id)
      if (this.$refs.orderstable) {
        let x = this.$refs.orderstable.computedItems;
        let row = this.$refs.orderstable.computedItems.findIndex(x => x.id === id);
        console.log(id, row, this.items);
        this.$refs.orderstable.selectRow(row);
        // this.scrollToRow(row);//TODO
      }

      let cell = this.currentCart().find(o => o.id === id)
      if (cell) {
        // this.toggleCellInclusion(id, 'switch')
        cell.disabled = false
        this.debounceCountTotalPrice()
      }


    }
    ,

    approveOrder(payment_method = 'agreement') {
      const purchaseUrl = '/api/v1/purchase/charts/todevice/v2';

      this.preparingCells = true

      // let agreementID = this.deviceStore.getAgreementID
      // let deviceID = this.deviceStore.getDeviceID
      // let appType = this.deviceStore.getAppType

      let deviceID = this.deviceStore.getDeviceID
      let agreementID = this.deviceStore.getAgreementID
      // let appType = this.userStore.getAppTypeByDeviceID(deviceID)


      let period = this.selectedPeriod

      // if (this.selectedPeriod === '6') {
      //   period = '1'
      // } else if (this.selectedPeriod === '12') {
      //   period = '0'
      // }

      let enabledItems = []
      let type = this.deviceStore.getAgreementProvider === 'UKHO' ? 'AVCS maps' : 's63 maps'
      this.enabledCartItems().forEach(item => {
        let enabledItem = {
          id: item.id,
          primarOrderTypeId: period,
          price: this.getCellPrice(item.id),
          monthCount: this.getDisplayPeriod(),
          type: type
        }
        enabledItems.push(enabledItem)
      })

      let comment = this.deviceStore.getAgreementProvider === 'UKHO' ? 'AVCS test' : 'Primar purchase'
      let agreement = this.deviceStore.getAgreementID
      let appType = this.deviceStore.getAgreementApplicationType
      let currency = this.deviceStore.getAgreementCurrency

      console.log("aaaa", agreement, appType, currency)

      let order = {
        deviceId: this.deviceStore.getDeviceID,
        price: this.getTotalPrice(),
        items: enabledItems,
        // items: [
        //   {
        //     id: "NO3B0408",
        //     primarOrderTypeId: periodperiod,
        //     monthCount: this.selectedPeriod,
        //     price: "4.56",
        //     type: "AVCS maps"
        //   }
        // ],
        comment: "AVCS test",
        paymentMethod: "agreement",
        currency: currency,
        agreementId: agreement,
        applicationTypeId: appType,
        domain: "getenc-onboard.com"
      }


      myAxios.post(purchaseUrl, order,
          {
            headers: {
              'content-type': 'application/json',
            }
          }
      ).then((resp) => {
        // this.momentaryStore.addTmp()
        router.push({name: "transactions", params: {qrevent: 'reload-transactions'}})
        // router.push({name: "transactions"})
      }).catch(err => {
        console.log("====================")
        console.log(err)
        router.push({name: "transactions", params: {qrevent: 'reload-transactions'}})
      })
    }
    ,
    getCellPrice(id) {
      let cellPrice = '?'

      let cellArray = this.$store.state.current.cart.cellPrices
      let objIndex = cellArray.findIndex((obj => obj.id === id));
      if (objIndex !== -1) {

        if (this.getCurrency() === 'USD')
          cellPrice = cellArray[objIndex].priceAgreementUsd

        if (this.getCurrency() === 'RUB')
          cellPrice = cellArray[objIndex].priceAgreementRub


        // if (this.hasValidAgreement && this.isRUBAgreement)
        //   cellPrice = cellArray[objIndex].priceAgreementRub + ' ₽'
        // if (this.hasValidAgreement && !this.isRUBAgreement)
        //   cellPrice = '$' + cellArray[objIndex].priceAgreementUsd
        // if (!this.hasValidAgreement)
        //   cellPrice = cellArray[objIndex].priceEuro + ' €'
      }

      return cellPrice
    },


    getCellDisplayPrice(id) {
      let price = this.getCellPrice(id)
      if (price !== '?') {
        if (this.getCurrency() === 'USD')
          price = '$' + price
        if (this.getCurrency() === 'RUB')
          price = price + '₽'
      }
      return price
    },

    getDisplayTotalPrice() {
      let price = this.getTotalPrice()
      if (price !== '?') {
        if (this.getCurrency() === 'USD')
          price = '$' + Number(price).toFixed(2)
        if (this.getCurrency() === 'RUB')
          price = Number(price).toFixed(2) + '₽'
      }
      return price
    },

    getDisplayPeriod() {
      if (this.selectedPeriod === '4')
        return '3'
      if (this.selectedPeriod === '1')
        return '6'
      if (this.selectedPeriod === '0')
        return '12'


    },

    toggleCellInclusion(id, type = 'switch') {
      this.$store.dispatch('current/updateCellStaus', {id: id, type: type}).then(status => {
        console.log(status)
        if (status) {
          // this.viewParent.ordersMap.unselectCell(id);
          eventBus.$emit('a1', id)
          // eventBus.$emit('a4', id)
          // this.viewParent.ordersMap.onCellClicked(id)
        } else {
          // const m = this.viewParent.ordersMap.match(id);
          // const scope = m.scope;
          // const field = m.field;
          // this.viewParent.ordersMap.replaceCell(scope, field, id)
          eventBus.$emit('a2', id)
        }

        if (type === 'switch')
          this.countTotalPrice()
      })
    }
    ,
    bandName(code) {
      return utils.getBandName(code);
    }
    ,
    cellById(id) {
      return this.$store.getters.cellById(id)
    }
    ,
    onRowSelected(items) {
      if (items.length > 0) {
        this.showCellOnMap(items[0].id, this.summary)
        this.highlightCell(items[0].id)
      }
    }
    ,
    sortBands(a, b) {
      return this.cellById(a.id).usageBand - this.cellById(b.id).usageBand
    }
    ,
    visibleRows() {
      let x = this.currentCart().slice().reverse()
      let y = x.sort(this.sortBands)
      // cellById(row.item.id).usageBand
      return y

    }
    ,
    enabledCartItems() {
      const enabledItems = this.currentCart().filter(function (e) {
        return !e.disabled
      })
      return enabledItems.slice().reverse()
    }
    ,
    highlightCell(id) {
      eventBus.$emit('make-cell-active', id)
    }
    ,
    showCellOnMap(id, items) {
      eventBus.$emit('pane2cell', {id: id, items: items, color: 'green'})
      const url = 'https://win1hetz.bgeo.fi:5005/' + id + '/{z}/{x}/{y}'
      eventBus.$emit('show-cell-on-map', url)
    }
    ,
    // selectCellByRoute() {
    //   console.log("cccc: selectCellByRoute")
    //   console.log("order selectCellByRoute")
    //   if (this.momentaryStore.route.chartsbyroute.length > 0) {
    //     // setTimeout(() => {
    //
    //     this.momentaryStore.route.chartsbyroute.forEach((item) => {
    //       this.viewParent.ordersMap.onCellClicked(item.trim());
    //     });
    //     this.viewParent.ordersMap.dispatchSelectedCells();
    //     this.momentaryStore.clearChartsByRoute()
    //
    //     // }, 1);
    //   }
    // }
    // ,
    // prepareCells() {
    //   console.log("cccc: prepareCells")
    //   let points = []
    //   this.routesStore.getShipWaypoints.forEach(item => {
    //     points.push({lat: item.lat, lon: item.lon})
    //   })
    //
    //   this.preparingCells = true
    //
    //   loadMSSChartsByRoute(points).then((response) => {
    //     this.preparingCells = false
    //     if (this.currentCartLen === 0)
    //       this.selectCellByRoute()
    //   })
    // }
    // ,
  }
}
</script>
<style scoped>
button:hover {
  color: black;
  background-color: white;
  outline: none !important;
}
</style>