<template>
  <div id="mymap" ref="mm" :style="{ height: height + 'px', minHeight: '400px'}"></div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import * as utils from "@/utils/utils";
import L from "leaflet";
import {ChartsLeaf} from "@/my-leaf/charts/charts-leaf";
import {eventBus} from "@/main";
import {mapStores} from "pinia";
import {useMomentaryStore} from "@/stores/momentary";
import {useRoutesStore} from "@/stores/routes";
import {useLogbookStore} from "@/stores/logbook";
import {useUserStore} from "@/stores/user";
import {useDeviceStore} from "@/stores/device";
import {loadMSSChartsByRoute} from "@/helpers/api";

export default {
  props: ['height', 'showRoute'],
  data() {
    return {
      preparingCells: false,
      ordersMap: new ChartsLeaf(),
    }
  },
  // computed: {
  //   windowHeight(){
  //     if (this.$route.name === 'data') return window.innerHeight - 150;
  //     return window.innerHeight - 90;
  //   }
  // },
  mounted() {


    this.ordersMap.init(this.$refs.mm, this.showRoute)

    eventBus.$off('clear-order')
    eventBus.$on('clear-order', () => {
      this.clearOrder()
    })

    eventBus.$off('a1')
    eventBus.$on('a1', (id) => {
      this.ordersMap.onCellClicked(id)
    })

    eventBus.$off('a4')
    eventBus.$on('a4', () => {
      this.ordersMap.dispatchSelectedCells();
    })

    eventBus.$off('a2')
    eventBus.$on('a2', (id) => {
      const m = this.ordersMap.match(id);
      const scope = m.scope;
      const field = m.field;

      this.ordersMap.replaceCell(scope, field, id)
    })

    let appstate = this.$store.state.charts.profile.appState;
    console.log(appstate)
    if (appstate ) {
      let lat = appstate.lat
      let lon = appstate.lon
      let cog = appstate.cog;
      let id = appstate.id;
      let days = appstate.days;
      let aindex = appstate.aindex;
      let name = appstate.name;
      let route = appstate.route;
      let sog = appstate.sog;
      let email = appstate.email;
      let isActive = appstate.isActive;
      let routeJson = appstate.routeJson
      this.ordersMap.showAppState(aindex, lat, lon, cog, id, isActive, name, route, sog, email, days, routeJson);

      if (this.$store.state.leaflet.map.lat === -1)
        this.ordersMap.paneToShip(aindex);
      // if (!localStorage.getItem('mapCenter'))
      //   this.ordersMap.paneToShip(aindex);
    }

    let keeped_route = JSON.parse(localStorage.getItem('route'))
    console.log("dd", keeped_route)
    // const pts = keeped_route ? JSON.parse(keeped_route)['route_temp_1'] : ""
    const pts = ""
    if (pts.length > 1) {
      this.ordersMap.add_pMeasure();
      this.ordersMap.pMeasure._toggleMeasure();
      this.ordersMap.pMeasure._toggleMeasure();
      // start line with first point of each polyline
      this.ordersMap.pMeasure._startLine(pts[0]);
      // add subsequent points:
      pts.forEach((point, ind) => {
        const latLng = L.latLng(point);
        this.ordersMap.pMeasure._mouseMove({latLng});
        this.ordersMap.pMeasure._currentLine.addPoint(latLng);
        // on last point,
        if (ind === pts.length - 1) {
          this.ordersMap.pMeasure._finishPolylinePath();
          // this.ordersMap.pMeasure._toggleMeasure();
        }
      });
    }
    //   window.addEventListener("resize", () => {
    //     if (this.$route.name === 'data') this.windowHeight = window.innerHeight - 150;
    //     else this.windowHeight = window.innerHeight - 90;
    //   });

    //map.init(this.$refs.mm);
    this.ordersMap.showAnotherRoute()
  },
  computed: {
    ...mapStores(useMomentaryStore, useRoutesStore),
    currentCartLen() {
      return this.$store.state.current.cart.s63cells.length
    },
  },
  methods: {
    init_map() {
      console.log("cccc: init_map");
      console.log("order init_map")
      // if (this.isMapInitialized)
      //   return
      // else
      //   this.isMapInitialized = true
      //
      // alert(1)

      console.log("hhh 1")

      console.log("c-- init map", new Date().getMinutes(), new Date().getSeconds())
      console.log("init map")
      // eventBus.$emit('restore-primar-tiles')
      ////eventBus.$emit('add-primar-controls')


      if (this.$store.state.charts.s63.products.all.length > 0) {
        // setInterval(() => {this.map.drawAllCells(this.$store.state.charts.s63.primar_map_info)}, 3000)
        this.ordersMap.drawAllCells(this.$store.state.charts.s63.primar_map_info);
        this.$store.dispatch('current/drawing', false).then(() => {
          this.tabOrderClicked()
        })

        // this.selectCellByRoute()
        console.log("order init_map 1")

      } else {
        this.$store.dispatch('getENCs').then(() => {
          console.log("c-- draw", new Date().getMinutes(), new Date().getSeconds())
          console.log('init draw all cells from', file)
          // setInterval(() => {this.map.drawAllCells(this.$store.state.charts.s63.primar_map_info)}, 3000)
          this.ordersMap.drawAllCells(this.$store.state.charts.s63.primar_map_info);
          this.$store.dispatch('current/drawing', false).then(() => {
            this.tabOrderClicked()
          })
          console.log("order init_map 2")

          // this.selectCellByRoute()
        });
      }

      console.log("hhh 2")


    },
    tabOrderClicked() {
      console.log("cccc: tabOrderClicked");
      console.log("order tabOrderClicked")
      if (this.routesStore.getShipWaypointsCount > 0) {
        this.prepareCells()
        // this.$refs["order-tab"].prepareCells()
      } else {
        this.clearOrder()
        // this.$refs["order-tab"].clearOrder()
      }
    },
    prepareCells() {
      console.log("cccc: prepareCells")
      let points = []
      this.routesStore.getShipWaypoints.forEach(item => {
        points.push({lat: item.lat, lon: item.lon})
      })

      this.preparingCells = true

      loadMSSChartsByRoute(points).then((response) => {
        this.preparingCells = false
        if (this.currentCartLen === 0)
          this.selectCellByRoute()
      })
    },
    selectCellByRoute() {
      console.log("cccc: selectCellByRoute")
      console.log("order selectCellByRoute")
      if (this.momentaryStore.route.chartsbyroute.length > 0) {
        // setTimeout(() => {

        this.momentaryStore.route.chartsbyroute.forEach((item) => {
          this.ordersMap.onCellClicked(item.trim());
        });
        this.ordersMap.dispatchSelectedCells();
        this.momentaryStore.clearChartsByRoute()

        // }, 1);
      }
    },
    clearOrder() {
      console.log("cccc: clearOrder")
      this.$store.dispatch('current/clearCart')
      this.$store.dispatch('addS63Items', [])
      // window.location.reload();
      // this.map.clearCellslayer()
      this.ordersMap.clearSelectedCells()
    }
  }
};
</script>

<style >
#mymap {
  border: solid 1px dimgray;
}
.leaflet-interactive:hover{
  stroke: #ffa011;
  stroke-width: 4;
}




</style>