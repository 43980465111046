import {useUserStore} from "@/stores/user";
import {useMomentaryStore} from "@/stores/momentary";
import {myAxiosRetry} from "@/services/myAxios";
import {addIndexToArray} from "@/utils/utils";
import store from "@/store";
import {useDeviceStore} from "@/stores/device";
import {file} from "paths.macro";
import {checkInternet} from "@/helpers/offline";
import {useRoutesStore} from "@/stores/routes";

let userStore, routesStore, deviceStore, momentaryStore

export function loadShips() {
    userStore = useUserStore()
    routesStore = useRoutesStore()
    momentaryStore = useMomentaryStore()

    return new Promise(resolve => {
        if (userStore.isInstallationsLoaded) {
            resolve()
        } else {
            let URL = "api/v1/installations/brief"

            momentaryStore.setBusy(true);
            momentaryStore.setAPIError(false)
            myAxiosRetry.get(URL).then(resp => {
                console.log("ships", resp.data)

                let ECDISList = resp.data.filter(function (e) {
                    return e.applicationId === 11
                });

                let MkartList = resp.data.filter(function (e) {
                    return e.applicationId !== 11
                });

                ECDISList = addIndexToArray(ECDISList);
                MkartList = addIndexToArray(MkartList);

                userStore.addECDISShips({ecdisList: ECDISList, devicesList: MkartList})
                routesStore.addShips(ECDISList)
                momentaryStore.setBusy(false);
                resolve();
            }).catch(err => {
                momentaryStore.setAPIError(true)
            });
        }
    })
}

export function loadInstallations() {
    userStore = useUserStore()
    routesStore = useRoutesStore()
    momentaryStore = useMomentaryStore()

    return new Promise(resolve => {
        if (userStore.isInstallationsLoaded) {
            resolve()
        } else {
            let URL = "api/v1/installations"

            momentaryStore.setBusy(true);
            momentaryStore.setAPIError(false)
            myAxiosRetry.get(URL).then(resp => {
                let instECDIS = resp.data.userInfos.filter(function (e) {
                    return e.appState !== null && e.applicationId === 11
                });

                let instDevices = resp.data.userInfos.filter(function (e) {
                    return e.appState !== null && e.applicationId !== 11
                });

                instECDIS = addIndexToArray(instECDIS);
                instDevices = addIndexToArray(instDevices);

                if (instECDIS.length > 0) {
                    userStore.addInstallations({ecdisList: instECDIS, devicesList: instDevices})
                    routesStore.addShips(instECDIS)
                }
                momentaryStore.setBusy(false);
                resolve();
            }).catch(err => {
                momentaryStore.setAPIError(true)
            });
        }
    })
}

export function loadAgreements() {
    deviceStore = useDeviceStore()
    momentaryStore = useMomentaryStore()

    return new Promise(resolve => {
        if (deviceStore.isAgreementLoaded) {
            resolve()
        } else {
            let URL = "api/v2/agreement"

            momentaryStore.setBusy(true);
            momentaryStore.setAPIError(false)
            myAxiosRetry.get(URL).then(resp => {
                deviceStore.addAgreements(resp.data)

                if (store.state.auth.user.role === 'distributor') {
                    let devices = []
                    resp.data.forEach(function (agreement) {
                        agreement.users.forEach(function (user) {
                            devices.push({deviceId:user.deviceId, applicationId:user.applicationId, shipName: user.shipName + " (" + agreement.name + ")"})
                        })
                    })
                    devices = addIndexToArray(devices);
                    userStore.addECDISAgreementShips({ecdisList: devices})
                    routesStore.addShips(devices)
                }

                momentaryStore.setBusy(false);
                resolve();
            }).catch(err => {
                momentaryStore.setAPIError(true)
            });
        }
    })
}

export function loadMSSChartsByRoute(points) {
    momentaryStore = useMomentaryStore()

    return new Promise(resolve => {
        let URL = "api/v1/mss/maps"

        momentaryStore.setBusy(true);
        momentaryStore.setAPIError(false)
        myAxiosRetry.post(URL, points).then(resp => {
            momentaryStore.addChartsByRoute(resp.data)
            momentaryStore.setBusy(false);
            resolve();
        }).catch(err => {
            momentaryStore.setAPIError(true)
        });
    })
}

export function loadCells(sourceType) {
    return new Promise(resolve => {
        store.commit("fickle/setPrimarENCDataReady", true)
        store.commit("fickle/setENCDataLoading", true)

        let encRequests
        if (sourceType === 'primar')
            encRequests = [store.dispatch("getENCCountries_Primar"), store.dispatch("getENCProducts_Primar"), store.dispatch("getENCs_Primar")]
        else if (sourceType === 'ukho')
            encRequests = [store.dispatch("getENCCountries_Primar"), store.dispatch("getENCProducts_UKHO"), store.dispatch("getENCs_UKHO")]
        else
            alert("Error")

        Promise.allSettled(encRequests).then(([r1, r2, r3]) => {
            let mapInfos = r3.value
            let errorFlag = false
            let links = store.state.charts.s63.products.linksToProducts;
            mapInfos.forEach(item => {
                item.productId = links[item.id];
                if (!item.productId) {
                    // alert(item.id)
                    errorFlag = true
                }
            })

            if (errorFlag) {
                // alert('Primar is not available, ' + file)
                console.log('PROBLEMA, ' + file)
            }

            store.commit("addPrimarMapInfos", Object.freeze(mapInfos));
            store.commit("fickle/setENCDataLoading", false)

            resolve(true)
        })
    })

}

export function loadOrders(deviceId) {
    userStore = useUserStore()
    momentaryStore = useMomentaryStore()

    const dataAlreadyLoaded = userStore.ordersByDevices.some(e => e.id === deviceId)
    if (dataAlreadyLoaded)
        return

    momentaryStore.setOrdersBusy(true);
    return new Promise(resolve => {
        let URL = "api/v1/orders/";
        myAxiosRetry.get(URL).then(resp => {
            userStore.addOrders({orders: resp.data, id: deviceId})
            momentaryStore.setOrdersBusy(false);
            resolve();
        });
    })
}