<template>
  <div>
    <b-overlay :show="!userStore.isInstallationsLoaded || ($store.state.auth.user.role === 'distributor' && !deviceStore.isAgreementLoaded)" opacity=0.9 bg-color="white" class="mt-4">
      <template #overlay>
        <div class="text-center">
          <b-icon icon="hourglass" font-scale="2" animation="spin"></b-icon>
          <p v-if="!userStore.isInstallationsLoaded">Loading installations...</p>
          <p v-if="$store.state.auth.user.role === 'distributor' && userStore.isInstallationsLoaded && !deviceStore.isAgreementLoaded">Loading agreements...</p>
        </div>
      </template>

      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <b-card header="DELIVERIES">
              <deliveries ref="deliveries"></deliveries>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </b-overlay>
  </div>
</template>

<script>
import Deliveries from "@/views/deliveries/Deliveries.vue";
import {mapStores} from "pinia";
import {useMomentaryStore} from "@/stores/momentary";
import {loadAgreements, loadInstallations, loadOrders, loadShips} from "@/helpers/api";
import {useDeviceStore} from "@/stores/device";
import {useUserStore} from "@/stores/user";

export default {
  components: {Deliveries},
  data() {
    return {
      selected_ship: "",
      ships: []
    }
  },
  watch: {
    'selected_ship'(newVal, oldVal) {
      this.deviceStore.selectDevice(newVal);
      this.$refs['deliveries'].checkUpdates()
    }
  },
  mounted() {
    //TODO
    // this.deviceStore.loadData()
    loadShips().then((response) => {
      if (this.deviceStore.isDeviceIDExist)
        this.selected_ship = this.deviceStore.getDeviceID
      else
            this.selected_ship = this.userStore.getECDISShips[0]?.value ?? ''
      this.ships = this.userStore.getECDISShips

      if (this.$store.state.auth.user.role === 'distributor') {
        loadAgreements().then((response) => {
          if (this.deviceStore.isDeviceIDExist)
            this.selected_ship = this.deviceStore.getDeviceID
          else
            this.selected_ship = this.userStore.getECDISShips[0]?.value ?? ''
          this.ships = this.userStore.getECDISShips
        })
      }
    })
  },
  computed: {
    ...mapStores(useMomentaryStore, useDeviceStore, useUserStore),
  }
}
</script>
<style scoped>
</style>