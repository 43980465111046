import { defineStore } from 'pinia'
import {useDeviceStore} from "@/stores/device";

export const useLogbookStore = defineStore({
    id: 'logbook',
    state: () => ({
        logbook: [],
    }),
    getters: {
        getLogsCount: (state) => state.logbook.length,
        getLatestLog: (state) => state.logbook[state.logbook.length - 1],
        getLatestShipLog: (state) => {
            const deviceStore = useDeviceStore()
            const deviceID = deviceStore.getDeviceID
            const logs = state.logbook?.filter((a) => a?.deviceId === deviceID)
            console.log("aaaa", logs, deviceID)
            return logs[logs.length - 1]
        }
    },
    actions: {
        addReport(payload) {
            let deviceStore = useDeviceStore()
            payload.deviceId = deviceStore.getDeviceID
            // console.log("aaaa", id, payload)

            this.logbook.push(payload)
        },
        reset() {
        }
    },
    persist: false
})
